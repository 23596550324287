button:focus {
  outline: 0 !important;
}

.btn {
  border-radius: $btn-border-radius;
  font-size: inherit;
}

.btn:focus {
  box-shadow: none;
}

.btn-xs {
  font-size: 0.7rem;
  padding: 0.2rem 0.4rem;
}

.btn-group-sm > .btn,
.btn-sm {
  font-size: 0.8rem;
}

.float-e-margins .btn {
  margin-bottom: 5px;
}

.btn-w-m {
  min-width: 120px;
}

.btn-primary.btn-outline {
  color: $navy;
}

.btn-success.btn-outline {
  color: $blue;
}

.btn-info.btn-outline {
  color: $lazur;
}

.btn-warning.btn-outline {
  color: $yellow;
}

.btn-danger.btn-outline {
  color: $red;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: #fff;
}

.btn.active,
.btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-new-primary {
  padding: 8px 22px 8px 22px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  background-color: $navy;
  border-color: $navy;
  height: 36px;
}
.btn-primary {
  color: #fff;
  background-color: $navy;
  border-color: $navy;
}
.btn-call-to-action {
  color: #fff;
  background-color: $secondary;
  border-color: $secondary;
}
.btn-call-to-action:hover {
  color: #fff;
  background-color: $secondary;
  border-color: $secondary;
}
.btn-dark-blue-action:hover {
  background-color: #0c80c5;
  color: #ffffff;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus {
  background-color: darken($navy, 3%);
  border-color: darken($navy, 3%);
  color: #ffffff;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: darken($navy, 3%);
  border-color: darken($navy, 3%);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: darken($navy, 3%);
  border-color: darken($navy, 3%);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-success {
  color: #fff;
  background-color: $blue;
  border-color: $blue;
}

.btn-success:hover,
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: darken($blue, 3%);
  border-color: darken($blue, 3%);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: darken($blue, 3%);
  border-color: darken($blue, 3%);
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: darken($blue, 3%);
  border-color: darken($blue, 3%);
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-info {
  color: #fff;
  background-color: $lazur;
  border-color: $lazur;
}

.btn-info:hover,
.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: darken($lazur, 3%);
  border-color: darken($lazur, 3%);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: darken($lazur, 3%);
  border-color: darken($lazur, 3%);
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: darken($lazur, 3%);
  border-color: darken($lazur, 3%);
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-default {
  color: inherit;
  background: white;
  border: 1px solid $border-color;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default,
.btn-default:active:focus,
.btn-default:active:hover,
.btn-default.active:hover,
.btn-default.active:focus {
  color: inherit;
  border: 1px solid #d2d2d2;
}

.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}

.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled],
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled]:active,
.btn-default.active[disabled],
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
  color: #cacaca;
}

.btn-warning {
  color: $yellow;
  background-color: #ffffff;
  border-color: $yellow;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus {
  color: #ffffff;
  background-color: darken($yellow, 3%);
  border-color: darken($yellow, 3%);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #ffffff;
  background-color: darken($yellow, 3%);
  border-color: darken($yellow, 3%);
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: darken($yellow, 3%);
  border-color: darken($yellow, 3%);
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-danger {
  color: #fff;
  background-color: $red;
  border-color: $red;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: darken($red, 3%);
  border-color: darken($red, 3%);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: darken($red, 3%);
  border-color: darken($red, 3%);
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: darken($red, 3%);
  border-color: darken($red, 3%);
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-link {
  color: inherit;
}

.btn-link:hover,
.btn-link:focus,
.btn-link:active,
.btn-link.active,
.open .dropdown-toggle.btn-link {
  color: $navy;
  text-decoration: none;
}

.btn-link:active,
.btn-link.active,
.open .dropdown-toggle.btn-link {
  background-image: none;
  box-shadow: none;
}

.btn-link.disabled,
.btn-link.disabled:hover,
.btn-link.disabled:focus,
.btn-link.disabled:active,
.btn-link.disabled.active,
.btn-link[disabled],
.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
.btn-link[disabled]:active,
.btn-link.active[disabled],
fieldset[disabled] .btn-link,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:active,
fieldset[disabled] .btn-link.active {
  color: #cacaca;
}

.btn-white {
  color: inherit;
  background: white;
  border: 1px solid $border-color;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white,
.btn-white:active:focus,
.btn-white:active:hover,
.btn-white.active:hover,
.btn-white.active:focus {
  color: inherit;
  border: 1px solid #d2d2d2;
}

.btn-white:active,
.btn-white.active {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}

.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
  background-image: none;
}

.btn-white.disabled,
.btn-white.disabled:hover,
.btn-white.disabled:focus,
.btn-white.disabled:active,
.btn-white.disabled.active,
.btn-white[disabled],
.btn-white[disabled]:hover,
.btn-white[disabled]:focus,
.btn-white[disabled]:active,
.btn-white.active[disabled],
fieldset[disabled] .btn-white,
fieldset[disabled] .btn-white:hover,
fieldset[disabled] .btn-white:focus,
fieldset[disabled] .btn-white:active,
fieldset[disabled] .btn-white.active {
  color: #cacaca;
}

.form-control,
.form-control:focus,
.has-error .form-control:focus,
.has-success .form-control:focus,
.has-warning .form-control:focus,
.navbar-collapse,
.navbar-form,
.navbar-form-custom .form-control:focus,
.navbar-form-custom .form-control:hover,
.open .btn.dropdown-toggle,
.panel,
.popover,
.progress,
.progress-bar {
  box-shadow: none;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all 0.5s;
}

.btn-rounded {
  border-radius: 50px;
}

.btn-large-dim {
  width: 90px;
  height: 90px;
  font-size: 42px;
}

button.dim {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  padding-top: 6px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  margin-bottom: 20px !important;
}

button.dim:active {
  top: 3px;
}

button.btn-primary.dim {
  box-shadow: inset 0 0 0 darken($navy, 6%), 0 5px 0 0 darken($navy, 6%), 0 10px 5px #999 !important;
}

button.btn-primary.dim:active {
  box-shadow: inset 0 0 0 darken($navy, 6%), 0 2px 0 0 darken($navy, 6%), 0 5px 3px #999 !important;
}

button.btn-default.dim {
  box-shadow: inset 0 0 0 darken($dark-gray, 6%), 0 5px 0 0 darken($dark-gray, 6%), 0 10px 5px #999 !important;
}

button.btn-default.dim:active {
  box-shadow: inset 0 0 0 darken($dark-gray, 6%), 0 2px 0 0 darken($dark-gray, 6%), 0 5px 3px #999 !important;
}

button.btn-warning.dim {
  box-shadow: inset 0 0 0 darken($yellow, 6%), 0 5px 0 0 darken($yellow, 6%), 0 10px 5px #999 !important;
}

button.btn-warning.dim:active {
  box-shadow: inset 0 0 0 darken($yellow, 6%), 0 2px 0 0 darken($yellow, 6%), 0 5px 3px #999 !important;
}

button.btn-info.dim {
  box-shadow: inset 0 0 0 darken($lazur, 6%), 0 5px 0 0 darken($lazur, 6%), 0 10px 5px #999 !important;
}

button.btn-info.dim:active {
  box-shadow: inset 0 0 0 darken($lazur, 6%), 0 2px 0 0 darken($lazur, 6%), 0 5px 3px #999 !important;
}

button.btn-success.dim {
  box-shadow: inset 0 0 0 darken($blue, 6%), 0 5px 0 0 darken($blue, 6%), 0 10px 5px #999 !important;
}

button.btn-success.dim:active {
  box-shadow: inset 0 0 0 darken($blue, 6%), 0 2px 0 0 darken($blue, 6%), 0 5px 3px #999 !important;
}

button.btn-danger.dim {
  box-shadow: inset 0 0 0 darken($red, 6%), 0 5px 0 0 darken($red, 6%), 0 10px 5px #999 !important;
}

button.btn-danger.dim:active {
  box-shadow: inset 0 0 0 darken($red, 6%), 0 2px 0 0 darken($red, 6%), 0 5px 3px #999 !important;
}

button.dim:before {
  font-size: 50px;
  line-height: 1em;
  font-weight: normal;
  color: #fff;
  display: block;
  padding-top: 10px;
}

button.dim:active:before {
  top: 7px;
  font-size: 50px;
}

.btn:focus {
  outline: none !important;
}
