table.table-hover > thead > tr {
  background-color: #f5f5f6;
}

table.table > tbody > tr > td {
  vertical-align: middle;
  z-index: 0;
}

// .pull-to-front {
//   z-index: 9999 !important;
// }

// /*
//  * Angular UI Tree
//  */
// .angular-ui-tree-handle {
//     background: none repeat scroll 0 0 #f3f3f4;
//     // border: 1px dashed #e7eaec;
//     color: inherit;
//     padding: 10px;
//     font-weight: normal;
// }
// .angular-ui-tree-handle:hover {
//     font-weight: bold;
//     cursor: pointer;
// }
// .angular-ui-tree-placeholder {
//     background: #f0f9ff;
//     // border: 1px dashed #bed2db;
//     -webkit-box-sizing: border-box;
//     -moz-box-sizing: border-box;
//     box-sizing: border-box;
// }
// .group-title {
//     background-color: #687074 !important;
//     color: #FFF !important;
// }
// .tree-node {
//     background: none repeat scroll 0 0 #f3f3f4;
//     // border: 1px dashed #e7eaec;
//     color: inherit;
//     padding: 15px;
//     border-radius: 3px;
//     word-break: break-all;
//     cursor: pointer;
// }

// .tree-node .btn {
//     min-width: 22px;
//     margin-right: 4px;
// }

// .tree-node-content {
//     margin: 5px 5px 5px 5px;
// }

// .tree-handle {
//     background: none repeat scroll 0 0 #f3f3f4;
//     // border: 1px dashed #e7eaec;
//     color: inherit;
//     padding: 10px;
// }

// .angular-ui-tree-placeholder {
//     background: #f0f9ff;
//     // border: 1px dashed #bed2db;
//     -webkit-box-sizing: border-box;
//     -moz-box-sizing: border-box;
//     box-sizing: border-box;
// }

// .sidebar-tree div {
//     background: none repeat scroll 0 0 transparent;
//     color: white;
//     padding: 5px;
//     border-radius: 3px;
// }

// .sidebar-tree div:hover {
//     background: none repeat scroll 0 0 #0C80C5;
//   }

// .sidebar-tree div.active {
//   border: 1px solid #61FF59;
// }

// .btn-transp {
//   color: #9ACC64;
// }

// .btn-transp:hover, .btn-transp:focus, .btn-transp:active, .btn-transp.active, .open .dropdown-toggle.btn-transp, .btn-transp:active:focus, .btn-transp:active:hover, .btn-transp.active:hover, .btn-transp.active:focus {
//   background-color: darken(#1460A5, 3%);
//   border-color: darken(#1460A5, 3%);
//   color: white;
// }

.btn-transp:active,
.btn-transp.active,
.open .dropdown-toggle.btn-transp {
  background-image: none;
}

// .btn-transp.disabled, .btn-transp.disabled:hover, .btn-transp.disabled:focus, .btn-transp.disabled:active, .btn-transp.disabled.active, .btn-transp[disabled], .btn-transp[disabled]:hover, .btn-transp[disabled]:focus, .btn-transp[disabled]:active, .btn-transp.active[disabled], fieldset[disabled] .btn-transp, fieldset[disabled] .btn-transp:hover, fieldset[disabled] .btn-transp:focus, fieldset[disabled] .btn-transp:active, fieldset[disabled] .btn-transp.active {
//   background-color: lighten(#1460A5, 4%);
//   border-color: lighten(#1460A5, 4%);
// }

#wrapper {
  height: 100%;
}

// nav.navbar-static-side {
//   height: 100%;
//   overflow-y: auto;
//   overflow-x: hidden;
// }

// ol.angular-ui-tree-nodes:nth-child(1) {
//   color: green;
//   font-weight: bold;
// }

// div.level1 {
//   color: $sidebar-text;
// }

// div.level2 {
//   color: darken($sidebar-text, 12%);
// }

// div.level3 {
//   color: darken($sidebar-text, 24%);
// }

// div.level4 {
//   color: darken($sidebar-text, 36%);
// }

// /*Member details account view*/

div.border-card {
  border: 1px solid #e7eaec;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}

// div.border-card:hover {
//   background: #F9F9F9;
// }

div.border-card a {
  color: $text-color;
}

// div.account-details dt {
//   width: 125px;
//   margin-left: 0px;
//   text-align: left;
//   font-weight: normal;
// }

// div.account-details dd {
//   margin-left: 0px;
// }

.client-detail {
  height: 100%;
}

// /*config editor*/
// div.border-card-padding {
//   border: 1px solid #E7EAEC;
//   margin-left: 0px;
//   margin-right: 0px;
//   margin-top: 10px;
//   padding-top: 5px;
//   padding-bottom: 5px;
//   padding-left: 15px;
//   padding-right: 15px;
// }

// div.padded {
//   padding-top: 15px;
//   padding-right: 15px;
//   padding-left: 15px;
//   padding-bottom: 15px;
// }

/*Navigation*/
$user-context-bar-text-color: #ccc;
.user-context-bar {
  width: 100%;
  text-transform: capitalize;
  color: $user-context-bar-text-color;
  .nav.navbar-right > li > a {
    color: $user-context-bar-text-color;
  }
  background-color: $nav-blue;
  ul.nav {
    background-color: $nav-blue;
  }
}
.user-side-bar {
  position: fixed;
  overflow: inherit;
  margin-top: 60px;
  width: 100%;
  min-height: 100%;
  text-transform: capitalize;
  color: $user-context-bar-text-color;
  .nav.navbar-right > li > a {
    color: $user-context-bar-text-color;
  }
  background-color: $nav-blue;
  ul.nav {
    background-color: $nav-blue;
  }
}
.mat-drawer.mat-drawer-side {
  position: fixed;
  margin-top: 60px;
}
.mat-drawer-inner-container {
  width: 100% !important;
  overflow-x: hidden !important;
}
.user-side-background-image {
  min-height: 100%;
  background-color: $nav-blue;
  // background-image: url("../../images/Nav_Background.png");
  background-repeat: no-repeat;
  background-position: bottom 70px right 1px;
  background-size: cover;
  transition: all 0.55s ease;
}
.grapical_notExpanded {
  position: absolute;
  left: -191px;
  width: 245px;
  bottom: 10%;
  z-index: -6;
  opacity: 0.5;
}
.grapical_design {
  position: fixed;
  width: 270px;
  bottom: 1%;
  z-index: -999;
  opacity: 0.5;
  object-fit: cover;
  height: 100%;
  object-position: 0% 50%;
  transition: width 0.55s ease;
}

.grapical_design_small {
  position: fixed;
  width: 69px;
  bottom: 1%;
  z-index: -999;
  opacity: 0.5;
  transition: width 0.5s ease;
}
.mat-drawer-content {
  z-index: 999 !important;
  background-color: rgb(250 250 250) !important;
}
.grapical_design_closed {
  object-fit: cover;
  height: 100%;
  width: 60px;
  object-position: 0% 50%;
  transition: width 0.4s ease;
}
.user-side-version {
  width: 14.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  color: #a9a9a9;
  font-size: 10px;
  line-height: 12px;
  position: fixed;
  background-color: #083658;
  bottom: 0%;
  padding: 14px 0;
  height: 20px;
  animation: slide-right 0.55s;
}
.user-side-version-false {
  width: 3rem;
  position: fixed;
  background-color: #083658;
  bottom: 0%;
  padding: 14px 0;
  height: 20px;
}

.navbar-top-links > .dropdown:hover .dropdown-menu {
  display: block; // only navbar Dropdowns should open on hover
}

.system-context-bar {
  .dropdown-toggle {
    line-height: 59px;
    height: 59px;
    font-size: 14px;
    font-weight: 600;
  }

  span i {
    margin-left: 0.5em;
  }

  .dropdown-menu {
    margin-top: -4px;
    border-top: 4px solid #00bfe9;
    border-radius: 0px;
    background-color: #013958;

    li {
      border-radius: 0;
      border-left: 1px solid gray;
      border-bottom: 1px solid gray;
      border-right: 1px solid gray;
      a {
        margin: 0px;
        font-weight: 600;
        &:hover {
          background-color: #013958;
          color: #efe8e8;
        }
      }
    }

    li.active {
      a {
        background-color: #013958;
      }

      a::after {
        content: "✓";
      }
    }
  }
}

.cheetah-nav {
  background-color: white;
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center;
  margin-left: -8px;
  // padding: 15px 10px 15px 5px;

  ul.nav {
    background-color: white;
  }

  // Top level nav links, default state
  .nav > li > a {
    color: #666;
    font-weight: 400;
    text-transform: uppercase;
  }

  // Top level menu links have the same color for active state & for hover state
  ul.nav > li.active > a,
  ul.nav > li:hover > a {
    color: #0c80c5;
  }

  // Submenus should not cover the active tab's bottom border
  ul.nav > li.active.dropdown > ul.dropdown-menu {
    top: 108%;
  }

  // Submenu active states
  ul.nav > li.dropdown > ul.dropdown-menu > li.active a {
    color: #0c80c5;
    background-color: white;
  }

  // Active submenu links should have the same hover state
  // background color as inactive links
  &:hover {
    // background-color: #f5f5f5;
  }
}

//   .nav > li {
//     // Lock height so active tab's bottom border
//     // won't change height of nav bar
//     max-height: 59px;
//     // Top-level nav link active state
//     &.active {
//       border-bottom: 4px solid #0C80C5;
//       background-color: white;
//     }
//   }
// }

// .nav-header {
//   background: transparent;
// }

.pace-done .nav-header {
  transition: all 0s;
}

.pace-done .navbar-static-side,
.pace-done .nav-header,
.pace-done li.active,
.pace-done #page-wrapper,
.pace-done .footer {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
}

// .navbar-fixed-top {
//   transition-duration: 0s;
// }

// body.canvas-menu nav.navbar-static-side {
//   background: nav-bg;
// }

/*Footer*/
.footer {
  background: none repeat scroll 0 0 $nav-blue;
  position: fixed;
  border-top: none;
  z-index: 9999;
}

.footer div {
  color: #999c9e;
}

// /*Activity reward*/
// table.shoping-cart-table {
//   tr td:last-child {
//     width: 200px;
//   }
//   tr td:first-child {
//     width: 120px;
//     height: 130px;
//   }
// }

// /*Account txn table*/
// table.txn > tbody > tr.redText > td:nth-child(2) {
//   color: red;
// }

// /*Reward for activity*/
// table.rewards {
//   border: 1px solid #e7e7e7;

//   > tbody > tr > td:first-child {
//     width: 90;
//     background-color: #23B9C8;
//     vertical-align: middle;

//     > div {
//       text-align: center;
//       color: #FFFFFF;

//       > h3 {
//         vertical-align: middle;
//       }
//     }
//   }

//   > tbody > tr > td:nth-child(2n) {
//     > div {
//       padding-left:10px;
//     }
//   }
// }

span.light-blue {
  color: $light-blue;
}

h2.elipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

span.table-label {
  font-size: 12px;
}

// div.import-dates {
//   margin: 20px 0 20px 0;
// }

// div.import-dates-activity {
//   margin: 0px;
//   padding-bottom: 10px;
// }

// .tree-node {
//   padding: 10px;
// }

textarea.vertical-expand {
  resize: vertical;
  min-height: 150px;
}

pre.notes {
  color: $text-color;
  background-color: #fffed4;
  margin-top: 5px;
  max-height: 200px;
  white-space: pre-wrap;
  overflow-x: auto;
  overflow-y: auto;
}

span.notes {
  max-width: 100%;
  word-wrap: break-word;
}

// span.text-right {
//   text-align: right;
// }

div.member-notes {
  margin-top: 20px;
}

// div.more-info {
//   text-align: center;
//   padding-top: 10px;
// }

.open-tickets {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 360px;
  z-index: 9999;

  .open-ticket {
    margin-left: 10px;
    margin-bottom: 40px;
    border: 1px solid #e7eaec;

    .ticket {
      height: 360px;
      width: 360px;
      overflow-y: auto;
    }
  }
}

// div#commentsLength {
//   overflow-y: auto;
// }

.tickets-list-popup {
  position: fixed;
  bottom: 0;
  right: 15px;
  z-index: 9999;

  ul.list-group {
    cursor: pointer;
  }

  .tickets-list {
    width: 360px;
    margin-bottom: 38px;
  }

  .tickets-button {
    margin-bottom: 38px;
  }

  div.tickets-subject {
    width: 280px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;
  }
}

div.ticket-header {
  background: #0c80c5;
  color: #ffffff;

  span > a.btn {
    color: #ffffff;
  }

  a {
    color: #ffffff;
  }

  div.subject {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 255px;
    overflow: hidden;
    font-weight: 600;
    margin-left: 5px;
    margin-top: 5px;
  }

  div.party-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 250px;
    overflow: hidden;
    font-weight: normal;
    margin-left: 5px;
    padding-bottom: 5px;
  }

  span.button-group {
    margin-top: 5px;
    margin-right: 5px;
  }
}

.feed-activity-list .feed-element {
  margin-bottom: 10px;
}

a.new-user {
  margin-bottom: 10px;
}

div.footer-center {
  text-align: center;
}

hr.shrinked {
  margin-top: 10px;
  margin-bottom: 10px;
}

// /*Breadcrumb color hack*/

ol.breadcrumb {
  background-color: transparent;
  margin-left: 10px;
  padding-top: 20px;
  > li {
    + li:before {
      padding: 0 5px;
      color: $breadcrumb-color;
      // [converter] Workaround for https://github.com/sass/libsass/issues/1115
      $nbsp: "\00a0";
      content: "#{$breadcrumb-separator}#{$nbsp}"; // Unicode space added since inline-block means non-collapsing white-space
    }
    > a {
      color: #337ab7;
    }
    > span {
      color: gray;
    }
  }
}

// /*Filtering tables*/

div.btn-group.filter {
  button.filter-button {
    width: 10rem;
  }

  li {
    margin-left: 10px;
  }
}

a.clear-filter {
  margin-top: 8px;
  margin-right: 20px;
}

// a.clear-filter-nrm {
//   margin-left: 5px;
//   margin-top: 8px;
//   margin-right: 0px;
// }

button.filter-tag {
  margin: 5px 10px 5px 0px;
  background-color: #ececec;
  border-radius: 10px;
  padding: 2px 10px 2px 10px;

  span.close:hover {
    font-weight: bold;
  }
}

a.filter-tag {
  margin: 0px 10px 5px 0px;
  background-color: #ececec;
  border-radius: 10px;
  padding: 2px 10px 2px 10px;

  span.close:hover {
    font-weight: bold;
  }
}

.import-message {
  word-wrap: break-word;
}

// .panel-group .panel-heading .panel-title button {
//   margin-top: -12px;
//   float: right;
//   margin-left: 5px;
// }

a.contact {
  margin-top: 10px;
}

span.badge.instruments {
  border: 1px solid #ffffff;
}

a.edit .details-panel-reduced-margin-right {
  margin-right: 5px;
}

div.json-preview pre {
  white-space: pre-wrap;
}

/*Tabs*/
div.tabs-container {
  ul.nav-tabs > li.active > a {
    color: #009dd9;
  }
}

// .linkDisabled {
//   cursor: not-allowed;
//   pointer-events: none;
//   color: grey;
// }

// ul.redemption-list {
//   word-break:break-word;
// }

div.border-card-details-panel {
  border-bottom: 4px solid #e7eaec;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: 400px;

  .darker-font-weight {
    font-weight: 600;
  }
}

/* Dropdown Button */
.status-dropbtn {
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.status-dropbtn:hover,
.status-dropbtn:focus {
  color: #3e8e41;
}

/* The container <div> - needed to position the dropdown content */
.status-dropdown {
  // position: relative;
  display: inline-block;
  z-index: 10;
}

/* Dropdown Content (Hidden by Default) */
.status-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.status-dropdown-font-size {
  font-size: 13px;
}

/* Links inside the dropdown */
.status-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.status-dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.status-dropdown-show {
  display: block;
}

span.status-under {
  z-index: 10;
}

.status-over {
  z-index: 100;
}

// status td vertical overflow
table.table > tbody > tr > td.status-editor {
  overflow: visible;
  z-index: 10;
}

// // sans-serif font for <i> tag
// .phone-serif-font {font-family: 'sans-serif'}

// .bordered-container-2px {
//   background-color: inherit;
//   border: 2px solid #e7eaec;
//   display: block;
//   margin-bottom: -2px;
//   padding: 10px 15px;
//   position: relative;
// }

.details-panel-text-overflow {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.details-panel-reduced-margin {
  margin-bottom: 5px !important;
}

.ibox-borders-without-top {
  border-left: 1px solid #e7eaec;
  border-right: 1px solid #e7eaec;
  border-bottom: 1px solid #e7eaec;
}

.normal-font-weight {
  font-weight: normal;
}

// Hover color on dropdowns
.dropdown {
  .checkbox {
    &:hover {
      background-color: #e9f5ff;
    }
  }
}

// .p-t-md {
//   padding-top: 25px;
// }
table.processing-details {
  margin: 0px;
  padding: 0px;
  border: none;
  width: 100%;

  > tr:hover {
    background-color: #dff4ff;
  }

  tbody tr td {
    padding: 8px 10px;
  }

  thead tr th {
    background-color: #dff4ff;
  }

  tr.filter td {
    input {
      width: 100%;
    }
  }
}

// //use when you want to alternate rows between blue and white
// .odd {
//   background-color: #dff4ff;
//   }
// .even {
//     background-color: #ffffff;
//   }

.border-left-2px {
  border-left-width: 2px;
}

// .cheetah-msgs {
//   display: block;
//   padding: (($line-height-computed - 1) / 2);
//   margin: 0 0 ($line-height-computed / 2);
//   font-size: ($font-size-base - 1); // 14px to 13px
//   line-height: $line-height-base;
//   word-break: break-all;
//   word-wrap: break-word;
//   background-color: $pre-bg;
//   border: 1px solid $pre-border-color;
//   border-radius: $border-radius-base;
//   max-height: 200px;
//   overflow-y: scroll;
// }

// .cheetah-validation-warn {
//     color: darkgreen;
// }

// .cheetah-validation-error {
//     color: crimson;
// }

.tickets-max-height {
  max-height: 75vh;
  overflow-y: scroll;
}

// /**
//   * Segment Filter
//   */

.segment-filter {
  padding: 0px;
}

div.segment-filter-container {
  height: 295px;
  overflow: auto;
  overflow-x: hidden;

  .segment-well {
    padding: 5px;
    margin-bottom: 5px;
  }

  .level_0 label {
    margin-left: 15px;
  }

  .level_1 {
    margin-left: 30px;
  }

  .level_2 {
    margin-left: 45px;
  }

  .level_3 {
    margin-left: 60px;
  }

  .level_4 {
    margin-left: 75px;
  }

  .level_5 {
    margin-left: 90px;
  }

  .level_6 {
    margin-left: 105px;
  }

  .level_7 {
    margin-left: 120px;
  }

  .level_8 {
    margin-left: 135px;
  }

  .level_9 {
    margin-left: 150px;
  }

  .level_10 {
    margin-left: 165px;
  }
}

.segment-filter-search-box {
  padding: 10px;

  input {
    padding-right: 27px;
  }

  button {
    position: absolute;
    top: 14px;
    right: 14px;
  }
}

//show button as link
.btn-as-link {
  background: none;
  border: none;
  display: inline;
  font: inherit;
  margin: 0;
  padding: 0;
  outline: none;
  outline-offset: 0;
  /* Additional styles to look like a link */
  color: #337ab7;
  cursor: pointer;
}

.btn-as-link:hover {
  background: none;
  border: none;
  display: inline;
  font: inherit;
  margin: 0;
  padding: 0;
  outline: none;
  outline-offset: 0;
  /* Additional styles to look like a link */
  color: #23527c;
  cursor: pointer;
}

// div.delivery-protocol .delivery-protocol-select {
//   margin-bottom: 15px;
//   color: black;
// }

// div.delivery-protocol select{
//   color: #555555;
// }

// //div.delivery-protocol button {
// //  border:1px solid white;
// //}

// div.input-vs-angular {
//   max-height: 255px;
//   width: 100%;
//   overflow: auto;
// }

// div.input-vs-angular.smaller-dropdown {
//   max-height: 175px;
//   width: 100%;
//   overflow: auto;
// }

// div.wysiwyg-editor {
//   min-height: 350px;
// }

// wysiwyg-editor.wysiwyg-comms-editor > div > div.wysiwyg-container > div.wysiwyg-editor {
//   max-height: 25em;
//   overflow-x: auto;
//   overflow-y: scroll;
// }

div.segment-frame {
  background: none repeat scroll 0 0 #f3f3f4;
  color: inherit;
  border-radius: 3px;
  word-break: break-all;
  margin-bottom: 3px;
  padding: 5px;

  label {
    margin-top: 2px;
  }
}

div.dropdown-item span:hover {
  background-color: #f5f5f5;
}

.interactions.feed-element .well {
  font-size: 13px;
  white-space: pre-line;
  word-break: break-all;
  background: #e7eaec;
}

.form-chat {
  textarea {
    resize: none;
  }

  span {
    vertical-align: bottom;
  }
}

.upload-input,
.privateKey,
.publicKey {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

// label.btn {
//   border-radius: $btn-border-radius;
//   margin: 5px 5px 5px 0px;
//   cursor: pointer;
// }

// label.btn-default {
//   color: inherit;
//   background: white;
//   border: 1px solid $border-color;
// }

// label.btn-default:hover, label.btn-default:focus, label.btn-default:active, label.btn-default.active, .open label.dropdown-toggle.btn-default, label.btn-default:active:focus, label.btn-default:active:hover, label.btn-default.active:hover, label.btn-default.active:focus {
//   color: inherit;
//   border: 1px solid #d2d2d2;
// }

label.btn-default:active,
label.btn-default.active,
.open label.dropdown-toggle.btn-default {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}

// label.btn-default.disabled, label.btn-default.disabled:hover, label.btn-default.disabled:focus, label.btn-default.disabled:active, label.btn-default.disabled.active, label.btn-default[disabled], label.btn-default[disabled]:hover, label.btn-default[disabled]:focus, label.btn-default[disabled]:active, label.btn-default.active[disabled], fieldset[disabled] label.btn-default, fieldset[disabled] label.btn-default:hover, fieldset[disabled] label.btn-default:focus, fieldset[disabled] label.btn-default:active, fieldset[disabled] label.btn-default.active {
//   color: #cacaca;
// }

// @media (min-width: 768px){
//   .seven-cols .col-md-1,
//   .seven-cols .col-sm-1,
//   .seven-cols .col-lg-1  {
//     width: 100%;
//     *width: 100%;
//   }
// }

// @media (min-width: 992px) {
//   .seven-cols .col-md-1,
//   .seven-cols .col-sm-1,
//   .seven-cols .col-lg-1 {
//     width: 14.285714285714285714285714285714%;
//     *width: 14.285714285714285714285714285714%;
//   }
// }

// @media (min-width: 1200px) {
//   .seven-cols .col-md-1,
//   .seven-cols .col-sm-1,
//   .seven-cols .col-lg-1 {
//     width: 14.285714285714285714285714285714%;
//     *width: 14.285714285714285714285714285714%;
//   }
// }

.colored-circle {
  border-radius: 0.8em;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  // margin-left: 5px;
  text-align: center;
  width: 1.6em;
}
li.ng-star-inserted ul > li .com_child {
  margin-left: 0px;
}

ul.ng-star-inserted li {
  margin-left: 0px;
}

.colored-square {
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em;
}

table.table-pointable {
  table-layout: fixed;

  > tbody {
    td {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 0;
      white-space: normal;
      word-break: normal;
    }

    tr:hover {
      background-color: #f5f5f6;
      cursor: pointer;
    }

    tr.active {
      > td {
        background: rgba(15, 90, 147, 0.08);
      }
    }
  }
}

// ul div.active-promo {
//   border: 2px solid $navy;
//   background-color: #ecf5de;
// }
ul div.com-active-promo {
  text-decoration: underline;
  font-weight: bold;
}
ul div.faded-promo {
  border: 2px solid #d3d3d3;
  opacity: 0.5;
  z-index: 100;
}

div.promo-element {
  padding: 10px;
  border: solid 1px gray;
  margin: 2px;
  border-radius: 20px;
}
div.com-promo-element {
  padding: 5px;
  align-items: center;
  justify-content: space-between;
  margin-left: 5px;
}

.tree-icons {
  & > div {
    position: absolute;
    right: 65px;
    top: -8px;
  }
  .colored-circle {
    position: absolute;
    right: 20px;
    top: -10px;
  }
}

div.promo-element-small {
  padding: 5px;
  border: solid 1px gray;
  margin: 2px;
  border-radius: 20px;
}

// .ui-select-clear {
//   margin-top: 9px;
//   margin-left: -70px;
//   font-size: 16px;
//   line-height: 0.75;
//   color: #999999;
//   cursor: pointer;
//   z-index: 999999;
//   position: absolute;
// }

// .dz-progress {
//   /* progress bar covers file name */
//   display: none !important;
// }

// .bg-white {
//   background-color: white;
// }

.dropdown-menu {
  margin: 0px;
}

// .ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   max-width: 100%;
// }
// .ui-select-bootstrap .ui-select-match-text {
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

.vertical-align-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.filler-gears {
  font-size: 240px;
  color: lightgray;
  text-align: center;
  align-items: center;
}

// .btn-dark-gray {
//  background: #989898;
//  color: #FFFFFF;
// }

.btn {
  margin-left: 2px;
  margin-right: 2px;
}

.btn-dark-blue {
  background: #0c80c5;
  color: #ffffff;
}

.btn-dark-blue-action {
  background: #ffffff;
  color: #0c80c5;
  border-color: #0c80c5;
}
.btn-dark-green {
  background: #67ac5b;
  color: #ffffff;
  margin-right: 2px;
  margin-left: 2px;
}

.btn-square {
  padding: unset;
  margin-bottom: 5px;
  max-width: 2.5em;
  min-width: 2.5em;
  max-height: 2.5em;
  min-height: 2.5em;
}

.bg-gray {
  background: #f2f2f2;
}

.program-card {
  padding: 40px;

  .program-card-header {
    border: solid 2px #e6e6e6;
    padding: 10px;
    position: relative;
  }

  .program-card-body {
    border-left: solid 2px #e6e6e6;
    border-right: solid 2px #e6e6e6;
    height: 150px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 5px;
    word-break: break-all;
  }

  .program-card-footer {
    border: solid 2px #e6e6e6;
    padding: 5px;
  }

  .program-card-selector {
    position: absolute;
    top: -35px;
    left: -2px;
    padding: 5px;
    //background: #DA534C;
    color: #ffffff;
    height: 28px;
  }

  .program-card-title {
    font-size: 16px;
    vertical-align: -webkit-baseline-middle;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .program-card-long-text {
    font-size: 18px;
    font-weight: 800;
    vertical-align: -webkit-baseline-middle;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    word-break: break-all;
    word-wrap: break-word;
  }

  .program-card-status {
    min-width: 70px;
    padding: 5px;
  }
}

.program-card-pre {
  background-color: white;
  border: none;
  padding: 2px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  word-break: normal;
  margin-top: 10px;
  white-space: pre-wrap;
}

.program-details-card-status {
  min-width: 70px;
  padding: 5px;
}
.program-card-bottom-yellow {
  background: #f1ad44;
  height: 10px;
}

.program-card-bottom-red {
  background: #da534c;
  height: 10px;
}

.program-card-bottom-blue {
  background: #57bfdf;
  height: 10px;
}

.program-card-bottom-green {
  background: #59b757;
  height: 10px;
}

.program-details {
  border-left: solid 2px #e6e6e6;
  padding: 40px;

  .program-details-title {
    border: solid 2px #e6e6e6;
    border-bottom: none;

    .program-details-title-text {
      font-size: 30px;
      padding: 10px;
    }

    .program-details-icon {
      position: relative;

      div {
        position: absolute;
        top: 22px;
      }
    }
  }
}
.program-details-desc {
  border-left: solid 2px #e6e6e6;
  border-right: solid 2px #e6e6e6;

  .program-details-desc-text {
    min-height: 200px;

    span {
      word-break: break-all;
    }
  }
}

.program-details-cards {
  border: solid 2px #e6e6e6;
  border-top: none;

  > div {
    padding: 30px;
  }

  .program-card-top {
    background: #e5e5e5;
    border: solid 1px #cccccc;
    border-bottom: none;
    height: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .program-card-middle {
    height: 40px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .program-card-bottom {
    height: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.program-bg-blue {
  background: #4089cb;
}

.program-bg-green {
  background: #59b757;
}

//   .program-bg-red {
//     background: #DA534C
//   }
// }

.integration-details-bar {
  background: #59b757;
  height: 10px;
  margin-right: 0px;
  margin-left: 0px;
}

.dashboard-card-yellow-top {
  border-top-color: #f1ad44;
  border-top-width: 10px;
  border-top-style: solid;
}

.dashboard-card-red-top {
  border-top-color: #da534c;
  border-top-width: 10px;
  border-top-style: solid;
}

.dashboard-card-green-top {
  border-top-color: #59b757;
  border-top-width: 10px;
  border-top-style: solid;
}

.dashboard-card-blue-top {
  border-top-color: #57bfdf;
  border-top-width: 10px;
  border-top-style: solid;
}

.dashboard-card-yellow-bottom {
  border-bottom-color: #f1ad44;
  border-bottom-width: 10px;
  border-bottom-style: solid;
}

.dashboard-card-red-bottom {
  border-bottom-color: #da534c;
  border-bottom-width: 10px;
  border-bottom-style: solid;
}

.dashboard-card-green-bottom {
  border-bottom-color: #59b757;
  border-bottom-width: 10px;
  border-bottom-style: solid;
}

.dashboard-card-blue-bottom {
  border-bottom-color: #57bfdf;
  border-bottom-width: 10px;
  border-bottom-style: solid;
}

// Form Validation

.form-control.has-error {
  border-color: red !important;
}

.has-error-label {
  color: red;
}

// Stacked FontAwesome Icons
.fa-stack .cornered-lr {
  position: absolute;
  bottom: -2px !important;
  left: 7px !important;
  text-align: right;
  line-height: 1em;
  font-size: 0.65em;
}

.fa-stack .cornered-ll {
  position: absolute;
  bottom: 0px !important;
  text-align: left;
  line-height: 1em;
  font-size: 0.75em;
}

.fa-stack .cornered-tr {
  position: absolute;
  top: 0px !important;
  left: 7px !important;
  text-align: right;
  line-height: 1em;
  font-size: 0.65em;
}

.fa-stack .cornered-tl {
  position: absolute;
  top: 0px !important;
  text-align: left;
  line-height: 1em;
  font-size: 0.75em;
}

.btn-sm > .fa-stack {
  width: 0.8em;
  height: 1.5em;
  line-height: 1.75em;
  left: -1px;
}
// END Stacked FontAwesome Icons

// // title/name header used in programs
// .flex-header {
//   display: flex;
//   flex-wrap: wrap;
//   align-items: flex-end;
// }

.flex-header-title {
  font-weight: 400;
  min-width: 200px;
  justify-self: flex-start;
}

.flex-header-name {
  align-self: center;
  min-width: 380px;
  width: fit-content;
  justify-self: flex-end;
  text-align: right;
  margin-left: auto;
  margin-bottom: 15px;
}

.flex-pull-right {
  display: flex;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  justify-content: center;
}

// /* Button Panel for sizing the smaller square FA icon-only buttons consistently no matter what FA icon is in there. */
div.button-panel-sq > .btn-sm {
  padding: unset;
  margin-bottom: 5px;
  max-width: 2.5em;
  min-width: 2.5em;
  max-height: 2.5em;
  min-height: 2.5em;
}

div.button-panel-sq > .btn-sm > .fa {
  margin: auto;
  vertical-align: middle;
}
// /* End Button Panel */

// button.btn > i.fa {
//   vertical-align: middle;
// }

.attribute-mappings {
  li.active {
    border: 2px solid #93c949;
  }
}
// a.btn > i.glyphicon-remove {
//   vertical-align: middle;
//   top: 0px;
// }

.panel-container {
  float: left !important;
}

.details-panel-min {
  padding: 0 40px 0 15px;
  width: 0;
  height: 50px;
}

.content-container-max {
  width: calc(100% - 100px);
  float: left;
}

.details-panel-header {
  margin: 0px !important;
  background-color: #009dd9;
  border-top: 0px !important;
}

.details-panel-header-alert {
  margin: 0px !important;
  background-color: #ff0000;
  border-top: 0px !important;
}

.details-panel-header-hide {
  background: rgba(0, 0, 0, 0) !important;
}

.shrinker {
  position: absolute;
  right: 25px;
  top: 10px;
  z-index: 10;
}

// a.disabled-link {
//    pointer-events: none !important;
//    cursor: default !important;
//    font-weight: 700 !important;
//    color: #676a6c !important;
// }

// // fix awesome bootstrap checkbox for use with FA5
// input[type="checkbox"] {
//   margin: 0;
//   &:checked + label::after {
//     font-family: "Font Awesome 5 Pro" !important;
//     content: "\f00c";
//     font-weight: 900 !important;;
//   }
// }
// // fix spacing for comm details panel checkbox
// .checkbox-only {
//   label {
//     &::before {
//       margin-left: 15px !important;
//     }

//     &::after {
//       margin-left: 15px !important;
//     }
//   }
// }

// .wysiwyg-toolbar-icon {
//   font-family: "Font Awesome 5 Pro" !important;
//   font-weight: 900 !important;;
// }

// /* Search Filter Directive */
// .search-filter-header-top, .search-filter-header-bottom {
//   margin-left: 0px;
//   margin-right: 0px;
// }

.search-filter-segmentless {
  height: 25px;
}

// .search-filter-row {
//   margin-left: 0px;
//   margin-right: 0px;
// }

// .search-filter-flex-row {
//   display:flex !important;
//   flex-wrap: wrap !important;
//   flex-shrink: 0;
// }

// .search-filter-flex-row-reverse {
//   flex-flow: row-reverse;
// }

// .uib-dropdown-item {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   max-width: 100%;
// }

.button-panel-sq > a.clear-filter {
  margin-top: 8px;
  margin-left: 20px;
}
// /* end Search Filter Directive */

.tabs-left ul {
  width: 20%;
  float: left;

  > li.tab-pane {
    float: none;
  }
}

// ul.nav-tabs > li.active > a {
//   margin-right: 0px;
// }
// //Promotion type colors

// .promotion-type-yellow {
//   background: #FCEF6D;
// }

// .promotion-type-red {
//   background: #FD9689;
// }

// .promotion-type-blue {
//   background: #57BFDF;
// }

// .promotion-type-green {
//   background: #59B757;
// }

// .promotion-type-purple {
//   background: #9370DB;
// }

// .promotion-type-lightgreen {
//   background: #00FF7F;
// }

// .promotion-type-cyan {
//   background: #00FFFF;
// }

// .promotion-type-beige {
//   background: #FFE4C4;
// }

// .promotion-type-grey {
//   background: #DCDCDC;
// }

// .promotion-type-lavender {
//   background: #E6E6FA;
// }

// .promotion-type-lemon {
//   background: #FFFACD;
// }

// .promotion-type-pink {
//   background: #FFB6C1;
// }
// SCROLLABLE TABS
.tabs-container > div[scrollable-tabs] {
  position: relative;
  cursor: pointer;

  &.scrollable {
    &:after,
    &:before {
      background: white;
      color: #009dd9;
      cursor: pointer;
      font-weight: bold;
      font-size: 1.25em;
      line-height: 2.5em;
      height: 2.25em;
      padding: 0 0.5em;
      position: absolute;
      top: 2px;
      z-index: 1;
      margin: 0 1px; // don't overlap borders
      font-family: "Font Awesome 5 Pro" !important;
    }

    &:before {
      content: "\f104";
    }

    &:after {
      content: "\f105";
      right: 0;
    }

    &.hide-left-arrow:before {
      content: "";
    }

    &.hide-right-arrow:after {
      content: "";
    }
  }

  > ul.nav-tabs {
    overflow: hidden;
    padding-left: 28px;
    white-space: nowrap;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    border: 1px solid #e7eaec;
    border-bottom: 1px transparent solid;
    background: white;

    > li {
      display: inline-block;
      float: none;
      > a {
        margin-right: 0px;
      }
      &:not(.active) {
        > a {
          border: none;
        }
        > a:hover,
        a:focus {
          border: none;
          box-shadow: 0 -3px 0 #676a6c inset;
        }
      }
      &.active {
        > a {
          color: #009dd9;
          border: none;
          box-shadow: 0 -3px 0 #009dd9 inset;
          &:hover {
            box-shadow: 0 -3px 0 #009dd9 inset;
          }
        }
      }
      &:last-child {
        margin-right: 26px;
      }

      &:focus {
        background: white;
      }
    }
  }
}

// .promo-pod {
//   box-shadow: 0px 2px 2px -1px rgba(0,0,0,0.2),
//               0px 4px 2px 0px rgba(0,0,0,0.14),
//               0px 1px 2px 0px rgba(0,0,0,0.12);
//   width: 95%;
//   margin-left: 1em;
//   > .tabs-container > div[scrollable-tabs] {
//     &.scrollable {
//       &:after, &:before {
//         background: #dff4ff;
//       }
//     }

//     > ul.nav-tabs {
//       background: #dff4ff;
//       border: 1px solid #ccc;
//       border-bottom: none;
//       > li {
//         margin-right: 5px;
//         &.active > a {
//           background: #dff4ff !important;
//         }      }
//     }
//   }
// }

// .promo-category-tabs > .tabs-container > div[scrollable-tabs] {
//   &.scrollable {
//     &:after, &:before {
//       background: #e6f3e1;
//     }
//   }
//   > ul.nav-tabs {
//     border-left: none;
//     border-right: none;
//     //background: #e6f3e1;
//     > li {
//       margin-right: 5px;
//     }
//   }
// }

// .bottom-border-separator {
//   border-bottom: 1px solid #e5e5e5;
// }

// .wysiwyg-toolbar-top {
//   white-space: normal !important;
// }

// .wysiwyg-toolbar {
//   text-align: center !important;
// }

// html {
//   font-size: unset !important;
// }

div.redemption-modal {
  div.form-group {
    div.row {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;

      div.col-md-8 {
        display: flex;
        flex-direction: column;
      }

      div.col-md-4 {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

.expandable-panel {
  transition: width 0.25s, height 1s, opacity 0.25s;
}

// .expandable-panel.expanding {
//   transition-delay: 200ms;
// }

// .animate-panel {
//   transition: opacity .2s, transform .2s, visibility .2s;
//   opacity: 0;
//   transform-origin: right;
//   transform: scaleX(0);
//   transition-delay: 0ms;
//   visibility: hidden;

//   &.animate-enter {
//     opacity: 1;
//     transform-origin: right;
//     transform: scaleX(1);
//     transition-delay: 250ms;
//     visibility: visible !important;
//   }
// }

// .animate-fade {
//   transition: opacity .2s, visibility .2s;
//   opacity: 0;
//   visibility: hidden;

//   &.animate-enter {
//     opacity: 1;
//     transition-delay: 25ms;
//     visibility: visible !important;
//   }
// }

// .animate-hidden {
//   display: none;
// }

// .animate-fade-in {
//   opacity: 1 !important;
//   visibility: visible !important;
// }

.uib-datepicker-popup.dropdown-menu {
  padding-bottom: 3px !important;
}

.table-buttons {
  > .btn-sm {
    padding-left: 0;
    padding-right: 0;
    width: 2rem;
    margin-left: 2px;
    margin-right: 2px;
  }
}

.reminder-modal .custom-group {
  border: 1px solid #e5e5e5;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.margin-0 {
  margin-bottom: 0px;
}

.logo-container {
  height: 59px;
  line-height: 59px;
  padding-left: 0px;
}

.logo-size {
  max-height: 30px;
  max-width: 150px;
}

.relative-position {
  position: relative;
}

#page-wrapper {
  position: relative !important;
  flex-shrink: 1;
  width: 100%;
}

.dl-horizontal dd:before,
.dl-horizontal dd:after {
  display: table;
  content: " ";
}
.dl-horizontal dd:after {
  clear: both;
}
@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dl-horizontal dd {
    margin-left: 180px;
  }
}

.custom-form-body {
  display: flex;
  max-height: 65vh;
}

.max-height-60vh {
  max-height: 60vh;
  // overflow-y: auto;
}

.padding-top-20px {
  padding-top: 20px;
}

.padding-top-10 {
  padding-top: 10px;
}

.ibox-title {
  border-top: none;
  padding: 10px 10px 8px 10px;
}

.blue-icon-color {
  color: #4089cb;
}

.green-icon-color {
  color: #9acc64;
}

@media (max-width: 1200px) {
  .responsive-no-scroll {
    margin-top: 0px !important;
  }
}

@media (max-width: 992px) {
  .responsive-no-scroll-md {
    margin-top: 0px !important;
  }
}

.word-break-break-all {
  word-break: break-all;
}

.close-button {
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }
}

.spin {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.absolute-position {
  position: absolute;
  top: 5px;
}

.relative-position {
  position: relative;
}

.margin-right-5 {
  margin-right: 5px;
}

.padding-20 {
  padding: 20px;
}

.width-3 {
  width: 3%;
}

.width-5 {
  width: 5%;
}

.width-6 {
  width: 6%;
}

.width-7 {
  width: 7%;
}

.width-8 {
  width: 8%;
}

.width-10 {
  width: 10%;
}

.width-7-rem {
  width: 7rem;
}

.width-8-rem {
  width: 8rem;
}

.width-10-rem {
  width: 10rem;
}

.width-12-rem {
  width: 12rem;
}

.width-12 {
  width: 12%;
}

.width-14 {
  width: 14%;
}

.width-15 {
  width: 15%;
}

.width-20 {
  width: 20%;
}

.width-23 {
  width: 23%;
}

.width-25 {
  width: 25%;
}

.width-30 {
  width: 30%;
}

.width-32 {
  width: 32%;
}

.width-33 {
  width: 33%;
}

.width-35 {
  width: 35%;
}

.width-40 {
  width: 40%;
}

.width-50 {
  width: 50%;
}

.width-55 {
  width: 50%;
}

.width-60px {
  width: 60px;
}

.width-70 {
  width: 70%;
}

.inline-table {
  display: inline-table;
}

.break-all {
  word-break: break-all;
}

.break-normal {
  word-break: normal;
}

.select-align-center {
  text-align-last: center;
}

.hotizontal-line {
  line-height: 2rem;
  text-align: center;
  vertical-align: middle;
}

.btn-attention {
  background-color: #ffffff;
  border-color: #e31c3d;
  color: #e31c3d;
}

.btn-dark-gray {
  background: #989898;
  color: #ffffff;
}

.margin-top-10 {
  margin-top: 10px;
}

.list-none {
  list-style-type: none;
}
.list-children-element {
  margin-left: 2px;
}

.uppercase {
  text-transform: uppercase;
}

.promotion-div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.panel-body {
  padding: 15px;
}

.panel-body:before,
.panel-body:after {
  display: table;
  content: " ";
}

.panel-body:after {
  clear: both;
}

.even-wider-modal-window {
  max-width: 85%;
}

.btn-default {
  margin-left: 2px;
  margin-right: 2px;
}

.fa-layers {
  height: 2em;
  width: 2.5em;
  left: -15px;
}

.filter-expander {
  margin-top: 10px;
  margin-bottom: 10px;
}

.filter-tags-text {
  font-size: 12px;
}

.filter-radio {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.full-width {
  width: 100%;
}

th.member-id-header {
  width: 6%;
}

th.member-status-header {
  width: 9%;
}

th.member-dob-header {
  width: 8%;
}

.font-relative-width {
  width: 10rem;
}

.font-relative-width-lg,
.filter-radio-width {
  width: 14rem;
}

.segment-filter-container .checkbox {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.wider-modal-window .modal-dialog {
  min-width: 67%;
}

.key-label {
  margin: 5px;
  padding: 5px 0px;
  font-weight: normal;
}

.product-name {
  margin-top: 2px;
  position: relative;
  margin-left: 35px;
}

.width-100 {
  width: 100%;
}

.green-color {
  color: green;
}

.blue-color {
  color: blue;
}

.red-color {
  color: red;
}

.close-button-tag {
  padding-left: 5px;
}
.col-md-offset-1 {
  margin-left: 8.3333333333%;
}

.attribute-mappings-details {
  border: 1px solid #93c949;
  border-radius: 5px;
  padding-bottom: 5px;
}

.attribute-group-details {
  border-bottom: 1px solid #93c949;
  padding-bottom: 10px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}
.margin-right-1 {
  margin-right: 1%;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.table-layout-auto {
  table-layout: auto !important;
}

.display-inline-block {
  display: inline-block;
}

.align-text-center {
  text-align: center;
}

.align-text-left {
  text-align: left;
}

.member-notes-tab-details-table {
  margin: 0px;
  padding: 0px;
  border: none;
  background-color: #dff4ff;
}

.clear-both {
  clear: both;
}

.text-muted {
  color: #e7eaec;
}

.display-none {
  display: none;
}

.overflow-wrap-break-word {
  overflow-wrap: break-word;
}

.search-filter-fields {
  width: 100%;
  min-width: 100%;
  height: 2.5rem;
}

.datepicker-mb .search-filter-fields {
  margin-bottom: 17px;
}

.search-filter-fields.search-filter-fields-wide {
  width: 16.25rem !important;
}

.search-filter-fields-wide .form-control {
  width: 15rem !important;
}

.checkbox label {
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.form-control:focus,
.single-line:focus {
  border-color: $navy;
  color: inherit;
}

.no-margin-table {
  margin: 0px;
  padding: 0px;
  border: none;
  background-color: dff4ff;
}

.ibox-footer {
  color: inherit;
  border-top: 1px solid #e7eaec;
  font-size: 90%;
  background: #ffffff;
  padding: 10px 20px;
  border-top: none;
}

.width-55-px {
  width: 55px;
}

.inline-block {
  display: inline-block;
}

.width-50-percentage {
  width: 50%;
}

.width-7-percentage {
  width: 7%;
}
// Radios and checkboxes on same line
.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;

  // These are used directly on <label>s
  &.disabled,
  fieldset[disabled] & {
    cursor: $cursor-disabled;
  }
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px; // space out consecutive inline controls
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.radio label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

.search-filter-radio-field {
  margin-top: 12px;
  margin-left: 0px;
}

.icon-picker-table {
  display: block;
  overflow-y: auto;
  max-height: 250px;
}

.border-none {
  border: none;
}
.buttonDateTemplate:hover {
  background: #eee;
  border-radius: 2%;
}

.buttonDateTemplate {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #676a6c;
  font-weight: bold;
  width: 95%;
  border: none;
  margin-bottom: 5px;
  background: white;
  height: 30px;
}
.divDateTemplate {
  display: flex;
  justify-content: center;
}
.promotion-type-yellow {
  background: #fcef6d;
}

.promotion-type-red {
  background: #fd9689;
}

.promotion-type-blue {
  background: #57bfdf;
}

.promotion-type-green {
  background: #59b757;
}

.promotion-type-purple {
  background: #9370db;
}

.promotion-type-lightgreen {
  background: #00ff7f;
}

.promotion-type-cyan {
  background: #00ffff;
}

.promotion-type-beige {
  background: #ffe4c4;
}

.promotion-type-grey {
  background: #dcdcdc;
}

.promotion-type-lavender {
  background: #e6e6fa;
}

.promotion-type-lemon {
  background: #fffacd;
}

.promotion-type-pink {
  background: #ffb6c1;
}

.btn-attention:hover,
.btn-attention:active,
.btn-attention.active,
.open .dropdown-toggle.btn-danger,
.btn-attention:active:focus,
.btn-attention:active:hover,
.btn-attention.active:hover,
.btn-attention.active:focus {
  background-color: darken($red, 3%);
  border-color: darken($red, 3%);
  color: #ffffff;
}

@media (min-width: $grid-float-breakpoint) {
  .navbar-left {
    float: left !important;
  }
  .navbar-right {
    float: right !important;
    margin-right: -$navbar-padding-horizontal;

    ~ .navbar-right {
      margin-right: 0;
    }
  }
}

.nav.navbar-right,
.nav.navbar-left {
  display: block;
}

.navbar-top-links li:last-child {
  margin-right: 10px;
}

.datePickerTemplate {
  display: flex;
  justify-content: center;
}

.custom-select {
  color: black;
}

.justify-center-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.no-padding {
  padding: 0px 0px 0px 0px;
}

.aligned-text {
  text-align: center;
}

.details-panel-information,
.details-panel .btn-success {
  color: white;
}

.top-5 {
  top: 5px;
}

.append-top {
  margin-top: 0px;
  border-top: 0px;
}
.promotion-button-height {
  height: 25px;
}
.overflow-y-auto {
  overflow-y: auto;
}

.margin-left-10px {
  margin-left: 10px;
}

.measure-list {
  .measure-group {
    .measure {
      border: 1px solid #f3f3f4;
      clear: both;

      div {
        display: inline-block;
        line-height: 40px;
      }

      .measure-name {
        padding-left: 15px;
      }

      .measure-actions {
        float: right;
        background: #f3f3f4;
        padding: 0 10px;
      }

      .measure-temporal-unit {
        float: right;
        padding-right: 15px;
      }
    }

    .measure-function {
      margin-left: 30px;
    }

    .function-controls {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.measures-header {
  font-size: 20px;
  line-height: 32px;
}

.margin-20px {
  margin: 20px;
}

.margin-20px-0 {
  margin: 20px 0;
}

.additional {
  margin: 0px 0px 0px 10px;
  padding: 0px;
  border: none;
  background-color: #dff4ff;
}

.additional-log {
  margin: 0px 0px 0px 10px;
  padding: 0px;
  border: none;
  background-color: #ffffaa;
}

.additional-log-message {
  margin: 0px 0px 0px 10px;
  padding: 0px;
  border: none;
  background-color: #eaf4d6;
}

.processing-log {
  background-color: #e0d6f4;
}

.additional-description {
  margin: 0px 0px 0px 10px;
  padding: 0px;
  border: none;
  background-color: #ffdcad;
}

.white-background {
  background: white;
}

.DFF4FF-background {
  background-color: #dff4ff;
}

.background-white {
  background-color: white;
}

@media (min-width: $screen-sm-min) {
  .control-label {
    padding-top: ($padding-base-vertical + 1); // Default padding plus a border
    margin-bottom: 0;
    text-align: right;
  }
}

@media (min-width: $screen-sm-min) {
  .control-label-left {
    padding-top: ($padding-base-vertical + 1); // Default padding plus a border
    margin-bottom: 0;
    text-align: left;
  }
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
  }
}

.bordered-container-2px {
  background-color: inherit;
  border: 2px solid #e7eaec;
  display: block;
  margin-bottom: -2px;
  padding: 10px 15px;
  position: relative;
}

.integration-stage-import-raw-data {
  font-family: "Lucida Console", monospace;
  white-space: pre;
  font-size: 0.8rem;
}

.white-space-normal {
  white-space: normal;
}

app-search-filter input[type="search"]::-webkit-search-decoration,
app-search-filter input[type="search"]::-webkit-search-cancel-button,
app-search-filter input[type="search"]::-webkit-search-results-button,
app-search-filter input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.rangeDiv {
  background-color: #f2dede;
  margin-bottom: 0.5vw;
}

.color-fff {
  color: #fff;
}

.closeBtn {
  opacity: 0.5;
}

.closeBtn:hover {
  opacity: 1;
}

.display-block {
  display: block;
}

.promo-pod {
  margin: 1rem;
}

/*DESKTOP VIEW*/

.promotion-container {
  margin: 0 0 1em 0;
  position: relative;
  height: auto;
  border: 1px solid #ccc;
  //padding-right: 1.5em;
  .toggle-up-container i {
    font-size: 2em;
    margin-bottom: 15px;
    margin-right: 5px;
    cursor: pointer;
  }
  .details-toggle {
    float: left;
    margin-left: 10px;
  }

  .overlay {
    background: lightgray;
    position: absolute;
    z-index: 100;
    opacity: 0.5;
    pointer-events: none;
    overflow: visible;
    width: 100%;
    height: 100%;
  }

  .notifications {
    top: 0;
    right: 0;
    position: absolute;
    z-index: 101;
  }

  .left-notifications {
    top: 0;
    left: 0;
    position: absolute;
    z-index: 101;
  }

  .notification {
    height: 1.6em;
    font-size: 1em;
    min-width: 9em;
    border-radius: 0;
    vertical-align: middle;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    color: white;
  }

  .left-notification {
    height: 1.6em;
    font-size: 1em;
    min-width: 10.7em;
    border-radius: 0;
    vertical-align: middle;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    color: white;
    background-color: #4089cb;
  }

  .info {
    background: #5bc0de;
  }

  .primary {
    background: #0275d8;
  }

  .hero-image {
    width: 180px;
    height: 120px;
    float: left;
    margin-right: 1em;
  }

  .promotion-details {
    font-size: 1em;
    float: left;
    width: 77%;
    .promotion-title-and-subtitle {
      margin-bottom: 0.75em;
      h3 {
        margin-top: 15px;
        font-size: 24px;
        font-weight: 500;
        width: 80%;
        margin-left: 10px;
      }
      .subtitle {
        margin-left: 10px;
      }
    }
    a:hover {
      text-decoration: none;
    }
    i {
      margin-left: 4px;
      &.fa-chevron-down {
        margin-right: 2.5em;
      }
    }
  }
  .action-item {
    text-align: center;
    background: linear-gradient(to bottom, #008ece 0%, #008ece 45%, #0072a5 65%, #0072a5 100%);
    position: relative;
    padding: 6px 12px;
    margin-top: 3em;
    border: 1px solid transparent;
    border-radius: 20px;
    max-width: 200px;
    max-height: 40px;
    float: right;
    a {
      color: white;
      font-size: 16px;
    }
    .promotion-action-item {
      cursor: context-menu;
    }
  }
  .no-top-margin {
    margin-top: 0 !important;
  }
}
/*
  text-align: center;
  width: 140px;
  height: 120px;
  color: #008ece;
  float: left;
  margin-left: -15px;*/

.points {
  font-size: 2em;
  text-align: center;
  width: 140px;
  height: 120px;
  color: #008ece;
  font-size: 2.5em;
  float: left;
  margin-left: -15px;
  background: linear-gradient(to bottom, #e7e6e7 0%, #e7e6e7 45%, #fff 65%, #fff 100%);
  .desktop-currency {
    display: block;
    font-size: 0.5em;
  }
  .desktop-amount {
    margin-top: 25px;
    margin-bottom: -10px;
  }
}

.desktop-description {
  display: block;
  margin-top: 130px;
  margin-left: 120px;
  width: 100%;
  font-size: 15px;
}

.desktop-description-child-promo {
  display: block;
  margin-left: 120px;
  width: 100%;
}

.reasonable-alternative-container {
  margin-left: 105px;
  font-size: 14px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.reasonable-alternative {
  margin-top: 30px;
}

.reasonable-alternative-container .fa-info-circle {
  color: #008ece;
  font-size: 18px;
  &:hover {
    cursor: pointer;
    color: #183e90;
  }
}

.desktop-disclaimer {
  font-size: 0.9em;
  font-style: italic;
  bottom: 0px;
  float: left;
  font-weight: normal !important;
}

.desktop-reward-image {
  text-align: center;
  width: 140px;
  height: 120px;
  color: #008ece;
  float: left;
  margin-left: -15px;
  background: linear-gradient(to bottom, #e7e6e7 0%, #e7e6e7 45%, #fff 65%, #fff 100%);
  img {
    margin-top: 28px;
    height: 60px;
    width: 60px;
  }
}
.promo-group {
  border-left: 1px;
  border-right: 1px;
  border-top: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-color: #ccc;
  background: #dff4ff;
  font-size: 12px;
}

.promo-group > li > a {
  padding: 10px 15px 10px 15px;
}

.promo-group > li > .active > a {
  box-shadow: 1px 1px 0 white;
}
.promotion-li {
  width: 100% !important;
  float: left !important;
}

.promotion-li-text {
  float: left;
  width: 90%;
  font-size: 15px;
  font-weight: bold;
}

.promotion-li-text-gr {
  float: left;
  width: 90%;
  font-size: 14px;
  font-weight: normal !important;
  list-style: none !important;
}

.li {
  display: list-item;
  margin-left: 2%;
  list-style: inside;
}

.promotion-li-icon {
  width: 5%;
}

.promotion-li-currency {
  display: inline-flex;
}

.or-promo-header {
  padding: 10px 15px 10px 15px;
}

/*-----------------MOBILE VIEW----------------*/

#mobile-promotion {
  height: 100%;
  border: 1px solid #ccc;
  width: 100%;
  display: none;
}

.image-points {
  max-height: 200px;
  min-height: 180px;
  max-width: 180px;
  margin-left: -15px;
}

.mobile-image-container {
  width: 180px;
  height: 120px;
  background-size: cover;

  img {
    width: 100%;
  }
}

.mobile-points {
  background: linear-gradient(to bottom, #e7e6e7 0%, #e7e6e7 45%, #fff 65%, #fff 100%);
  height: 60px;
  text-align: center;
}

.mobile-reward-image {
  background: linear-gradient(to bottom, #e7e6e7 0%, #e7e6e7 45%, #fff 65%, #fff 100%);
  height: 75px;
  text-align: center;

  img {
    height: 60px;
    width: 60px;
  }
}

.mobile-points {
  p {
    color: #008ece;
    font-size: 26px;
    line-height: 45px;
  }
  .mobile-currency {
    font-size: 12px;
    margin-top: -35px;
  }
}

#mobile-promotion .details {
  position: absolute;
  margin-left: 185px;
  height: 180px;
  top: 0;
}

.mobile-title {
  font-size: 18px;
  margin-top: 20px;
  line-height: 20px;
  font-weight: 500;
  color: #444;
  margin-right: 15px;
}

.mobile-subtitle {
  margin-top: -10px;
  margin-bottom: 10px;
}

.details-link {
  color: #008ece;
  margin-top: -12px;
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
}

.mobile-description {
  height: auto;
  margin-top: 20px;
}

#mobile-disclaimer {
  margin-top: 1em;
  font-size: 0.9em;
  font-style: italic;
}

.fa-angle-up.mobile {
  font-size: 2em;
}

.mobile-action {
  text-align: center;
  background: linear-gradient(to bottom, #008ece 0%, #008ece 45%, #0072a5 65%, #0072a5 100%);
  position: absolute;
  bottom: 10px;
  width: 190px;
  padding: 6px 12px;
  border-radius: 20px;
  border: 1px solid transparent;
  a {
    font-size: 16px;
    color: white;
  }
}

.mobile-alternative-container {
  font-size: 12px;
  margin-left: 0px;
  .fa-info-circle {
    font-size: 16px;
    color: #008ece;
  }
}

.mobile-alternative-container .fa-info-circle:hover {
  cursor: pointer;
  color: #183e90;
}

#mobile-promotion .toggle-up-container {
  margin-bottom: 20px;
}

.view-desktop {
  display: none;
}

@media screen and (max-width: 1800px) {
  #desktop-promotion {
    display: none;
  }
  #mobile-promotion,
  .view-desktop {
    display: inline-block;
  }
}

@media screen and (max-width: 991px) {
  #desktop-promotion {
    display: block;
  }
  #mobile-promotion,
  .view-desktop {
    display: none;
  }
}

@media screen and (max-width: 950px) {
  #desktop-promotion {
    display: none;
  }
  #mobile-promotion {
    display: inline-block;
  }
}

/*---------------------SHARED------------------------*/

.tooltip-inner {
  background: #183e90;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #183e90;
}

.reasonable-alternative-container a:hover,
.mobile-alternative-container a:hover {
  text-decoration: underline;
}

.user-name {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: -4px;
  i {
    font-size: 16px;
  }
}
.profile-deets {
  .user-info {
    position: relative;
    .logout-link {
      width: 150px;
      position: absolute;
      top: 27px;
      left: 15px;
      padding: 0.5em;
      border-radius: 5px;
      a:hover {
        text-decoration: none;
      }
    }
    .id-number {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .points-count {
    &.negative-balance {
      color: #ff0000;
    }
  }
  .balance-label {
    float: left;
    margin-left: 2px;
    margin-top: -5px;
    &.large-balance {
      margin-left: 0;
      margin-top: 0;
      float: none;
    }
  }
}
//.balance-icon{
//  @media (max-width:$screen-sm-max){
//    float: left;
//    height: 40px;
//    margin: 2px 15px 0 0;
//    width: 45px;
//  }
//}
.points-total {
  background-color: #fff;
  text-align: center;
  font-size: 1.5em;
  position: relative;
  right: -15px;
  padding-top: 15px;
  padding-bottom: 25px;
  small {
    display: block;
    font-size: 0.7em;
    line-height: 0.5;
  }
  &.single-line-currency {
    padding-top: 22px;
  }
  &.negative-balance {
    color: #ff0000;
  }
}

.detail-chevron {
  margin-top: 15px;
}

.display-template-buttons {
  display: flex;
  justify-content: center;
}

.display-title {
  background-color: #fff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 3px 1px 1px;
  color: inherit;
  margin-bottom: 0;
  padding: 14px 20px 7px;
  min-height: 48px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-20 {
  margin-top: 20px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-15 {
  padding-bottom: 15px;
}

.contenteditable {
  min-height: 200px;
  border: solid;
}

.document-modal-li-padding {
  padding-top: 0px;
  padding-bottom: 0px;
}

.font-size-16 {
  font-size: 16px;
}

.button-inside-input {
  margin-left: -40px;
  z-index: 100;
}

//Pop up filter input width style change
.filter-dd .search-filter-fields {
  width: 100% !important;
  // margin: 10px 0;
}
.filter-date .font-relative-width {
  width: 100% !important;
}
.filter-dd .search-filter-fields .dropdown {
  width: 100% !important;
}
.filter-dd .search-filter-fields .dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto 0px;
}
.filter-ff .search-filter-fields {
  width: 100% !important;
  margin: 10px 0;
}
.filter-ff .search-filter-fields .font-relative-width {
  width: 100% !important;
}
// div.btn-group.filter button.filter-button {
//   width: 18.5rem;
// }
// .dropup .dropdown-toggle::after {
//   display: inline-block;
//   margin-left: 13.255em;
// }

//PopUp Filter alignment Fix
.filter-dd .ng-select {
  width: auto;
}
.filter-date ngb-datepicker {
  width: 100% !important;
}
.ngb-dp-month {
  width: 100%;
}
.ngb-dp-weekdays,
.ngb-dp-week {
  justify-content: space-evenly;
}
.btn-outline-primary .filter:hover {
  color: #307cc0;
  background-color: rgb(63 98 199 / 12%);
  border-color: #1c84c66b;
}

.filters_container {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  margin: 20px 0;
}
.filters_container1 {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-end;
  margin: 20px 0;
}
.filters_container2 {
  position: relative;
  width: 100%;
  justify-content: space-between;
  margin: 20px 0;
}
.search-input {
  width: 268px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 35px 0 10px;
  left: -15px;
  border-radius: 5px;
  border: 1px solid #e5e6e7;
}

.search-input1 {
  position: relative;
  left: 14px;
  display: flex;
  align-items: center;
  padding: 0 35px 0 10px;
  width: 268px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e5e6e7;
}

.search-input2 {
  position: relative;
  width: 268px;
  display: flex;
  align-items: center;
  padding: 0 35px 0 10px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e5e6e7;
}

.search-input-field {
  width: 100%;
  border: none;
  outline: none;
}
::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.search {
  position: absolute;
  right: 10px;
  top: 10px;
}

.right_container {
  position: absolute;
  right: -15px;
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.right_container1 {
  display: flex;
  position: absolute;
  right: 18px;
  flex-direction: row;
  gap: 25px;
}
.right_container2 {
  display: flex;
  flex-direction: row;
  gap: 25px;
}
.right_container3 {
  float: right !important;
  display: flex;
  flex-direction: row;
  gap: 25px;
  margin-bottom: 20px;
}

.filter {
  gap: 10px;
  width: 145px;
  min-width: 100%;
  height: 38px;
  text-transform: uppercase;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    color: #307cc0;
    background-color: rgb(63 98 199 / 12%);
    border-color: #1c84c66b;
  }
}
.filter-btn {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 145px;
  height: 38px;
  color: #307cc0;
  border: 1px solid #307cc0;
  text-transform: uppercase;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  &:hover {
    color: #307cc0;
    background-color: rgb(63 98 199 / 12%);
    border-color: #1c84c66b;
  }
}

.add-spo-btn {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  height: 38px;
  color: #307cc0;
  border: 1px solid #307cc0;
  text-transform: uppercase;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  &:hover {
    color: #307cc0;
    background-color: rgb(63 98 199 / 12%);
    border-color: #1c84c66b;
  }
}

.des_container {
  display: flex;
  margin-left: -15px;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 183px !important;
}
.mat-overflow {
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: 10px;
}
.mat-tab-body-content {
  height: 100%;
  overflow: hidden !important;
}
.mat-tab-label {
  text-transform: uppercase !important;
  color: black !important;
  font-weight: 500 !important;
  opacity: 1 !important;
  font-size: 16px !important;
  line-height: 20px !important;
}
.mat-tab-header {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  width: 100% !important;
}
.mat-tab-label.mat-tab-label-active {
  color: #0f5a93 !important;
}
.badgeactive {
  background-color: #3d853d;
  padding: 7px 10px 7px 10px;
  font-size: 12px;
}
.new-badge {
  width: auto;
  height: 24px;
  padding: 3px 10px 3px 10px;
}
.badge-statusmsg {
  color: white;
  width: 46px;
  height: 18px;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
}
.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}
.model-sub-heading {
  font-size: 25px;
  color: black;
  font-weight: bold;
  margin-bottom: 15px;
}

.model-heading {
  font-size: 16px;
  color: #676a6c;
}
.material-icons {
  cursor: pointer;
}

.right-margin {
  margin-right: 15px;
}

ng-star-inserted:hover {
  border-bottom: 1px solid red !important;
}

.dec_logo {
  width: 53px;
  height: 53px;
}
table {
  border-collapse: collapse !important;
}
table tr td {
  border-right: 1px solid #e7e7e7;
}

table.table tbody tr td,
table.table thead tr th,
table.table thead {
  border-right: 1px solid #e7e7e7;
}
.link_next_page {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #0f5a93;
}

.table_field_name {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px !important;
  color: #212529;
}
.table_trace_id {
  width: 300px;
}
.modal.show .modal-dialog {
  transform: none;
  width: auto;
  height: auto;
  margin-top: 8%;
}

.btn-pop-cancel {
  width: 80px;
  height: 32px;
  border: 1px solid #0f5a9380;
  border-bottom: 1px solid #0f5a93;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500, Medium;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  background: #ffffff;
  color: #0f5a93;
  &:hover {
    color: #307cc0;
    background-color: rgb(63 98 199 / 12%);
    border-color: #1c84c66b;
  }
}
.btn-pop-cancel-wider {
  width: 135px;
  height: 32px;
  border: 1px solid #0f5a9380;
  border-bottom: 1px solid #0f5a93;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500, Medium;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  background: #ffffff;
  color: #0f5a93;
  &:hover {
    color: #307cc0;
    background-color: rgb(63 98 199 / 12%);
    border-color: #1c84c66b;
  }
}
.btn-pop-success {
  width: 80px;
  height: 32px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 500, Medium;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #ffffff;
  background: #0071bc;
  border-radius: 4px;
  text-transform: uppercase;
  &:disabled {
    background-color: #3477b3;
    border-color: #3477b3;
  }
}

.btn-pop-success-wider {
  width: 135px;
  height: 32px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 500, Medium;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #ffffff;
  background: #0071bc;
  border-radius: 4px;
  text-transform: uppercase;
  &:disabled {
    background-color: #3477b3;
    border-color: #3477b3;
  }
}

.btn-pop-delete {
  margin-right: 1rem;
  width: 80px;
  height: 32px;
  border: none;
  outline: none;
  font-style: normal;
  background: #e31c3d;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500, Medium;
  line-height: 20px;
}

.btn-save {
  width: 80px;
  height: 32px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #ffffff;
  background: #0071bc;
  border-radius: 4px;
}

.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 30px !important;
  margin-bottom: 48px !important;
}
.filter_model {
  .modal.show .modal-dialog {
    width: 785px !important;
  }
}
.model_container {
  padding: 3rem 4rem 5rem 4rem;
}

.btn-add {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 159px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  background: #307cc0;
  border-radius: 4px;
  text-transform: uppercase;
  color: #ffffff;
  outline: none;
  border: none;
}
.pop-btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-bottom: 20px;
}

input::placeholder {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px;
  display: flex !important;
  align-items: center !important;
  color: rgba(0, 0, 0, 0.38) !important;
}

.custom-droupdown > div > div > div > buttom {
  width: 100% !important;
}

.custom-droupdown > div > div > div > buttom::after {
  position: absolute;
  left: 10px;
  transform: translate(0px -50%);
  top: 50%;
}

.fullname {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 143%;
  letter-spacing: 0.15px;
  color: #000000;
}
.form-control,
.single-line {
  border: 1px solid #e4e4e4 !important;
  border-radius: 4px !important;
  height: 40px !important;
}

.search-filter-bottom {
  margin-bottom: 15px;
}
.autocomplete-container .input-container input {
  padding: 0 40px 0 12px !important;
}
.wh-100 .autocomplete-container {
  width: 100%;
}
@media (min-width: 576px) {
  .modal-sm {
    max-width: 32.5rem !important;
  }
}

.delete-model {
  margin-top: 100px !important;
}

@keyframes slide-right {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.custom-mat-tab-width .mat-tab-label-container {
  width: calc(100% - 300px);
}

.import-list-header {
  background-color: #f5f5f6;
  height: 55px;
  margin-right: 0px;
  margin-left: 0px;
}

.import-list {
  padding-left: 0px;
  list-style-type: none;
}

.import-list-element > .card {
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
}

.import-list-element > .card > .card-header {
  background: white;
}

.import-list-icon {
  padding-top: 6px;
}

.primary-icon {
  color: #0275d8;
}

.dndPlaceholder {
  min-height: 58px;
  border: 1px solid grey;
  background-color: rgba(0, 0, 0, 0.1);
}

#import_file_panel > .card-body {
  background-color: #dff4ff;
}

.import-mapping-header {
  border: 1px solid lightgrey;
  border-radius: 5px;
}

.import-mapping-body {
  border-radius: 0px 0px 5px 5px;
}

.import-list-header h4 {
  line-height: 50px;
}

.selected-import-file > .card > .card-header {
  background-color: #dff4ff;
}

.align-left {
  text-align: left !important;
}

.sponsorship-modal-promotions {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 270px;
}

.overflow-hidden {
  overflow: hidden !important;
}

.profile-organization-logo {
  width: 125px;
}

.organization-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #c4c4c4;
  border-radius: 50%;
}

.organization-logo-lg {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
  max-height: 100px;
  width: auto;
  height: auto;
}

button.btn-add.btn-long {
  width: 180px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.copy-paste {
  display: inherit;
  margin-bottom: 15px;
  padding-left: 0px;
}

.edit-json-textarea {
  max-width: 100%;
  resize: vertical;
}

div.modal-body app-json-preview pre {
  margin-bottom: 0px !important;
  width: 100% !important;
}

div.modal-body app-json-preview .row {
  margin-left: 0px;
  margin-right: 0px;
  width: 100% !important;
}

#contentJson {
  height: 100% !important;
}

.margin-auto {
  margin: auto !important;
}

.marign-right-auto {
  margin-right: auto !important;
}

#contentJson {
  margin: 0;
  border-radius: 0;
}

.backdrop {
  overflow: auto;
}

.highlights {
  white-space: pre-wrap;
  word-wrap: break-word;
}

#contentJson {
  color: #444; /* or whatever */
  background-color: transparent;
}

.highlights {
  color: transparent;
}

mark {
  color: transparent;
  background-color: #d4e9ab; /* or whatever */
}

.backdrop {
  background-color: #fff; /* or whatever */
}

.json-error-message {
  margin-top: 10px !important;
}
